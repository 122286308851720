import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { getAccessTokenThatMinValidForNMinutes } from 'apis/api';
import { buildUrl, SPECIAL_AGENT_WS_URL } from 'core/endpoints';
import { SpecialAgentWSType, TOKEN_PADDING_MINUTES } from './constents';
import { ITaskContentOut } from 'types/specialAgent';

const getSpecialAgentUlr = async (p_uid: string) => {
  const { ephemeralToken } = await getAccessTokenThatMinValidForNMinutes(TOKEN_PADDING_MINUTES);

  const queryParams = { token: ephemeralToken, p_uid };

  const url = buildUrl(SPECIAL_AGENT_WS_URL, {}, queryParams);

  return url;
};

interface WebSocketHook {
  taskContents: ITaskContentOut[];
  isFeedbackRequired: boolean;
  isLoading: boolean;
  sendQuery: (query: string, queryFile?: string) => void;
  sendFeedback: (response: 'yes' | 'no') => void;
}

export const useSpecialAgentWS = (projectUid?: string): WebSocketHook => {
  const navigate = useNavigate();
  const ws = useRef<WebSocket | null>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [taskContents, setTaskContents] = useState<ITaskContentOut[]>([]);
  const [isFeedbackRequired, setIsFeedbackRequired] = useState(false);

  useEffect(() => {
    return () => {
      if (ws.current) {
        ws.current.close();
      }
    };
  }, []);

  const updateProgress = (newContent: ITaskContentOut) => {
    setIsLoading(false);
    setTaskContents((prev) => [...prev, newContent]);
  };

  const sendQuery = async (query: string, queryFile?: string) => {
    setIsLoading(true);
    setTaskContents([]);

    if (!projectUid) {
      toast.error('Invalid project id');
      return;
    }
    const url = await getSpecialAgentUlr(projectUid);
    ws.current = new WebSocket(url);

    ws.current.onopen = () => {
      if (ws.current) {
        ws.current.send(JSON.stringify({ question: query, file: queryFile }));
      } else {
        toast.warning('Something went wrong');
      }
    };

    ws.current.onmessage = (event) => {
      const data: ITaskContentOut = JSON.parse(event.data);

      switch (data.type) {
        case SpecialAgentWSType.ERROR: {
          toast.error(data.error_message);
          break;
        }
        case SpecialAgentWSType.TASK_CREATED: {
          const taskId = data.task_id;

          if (!taskId) {
            toast.error('Something went wrong!');
            break;
          }

          navigate(`/special-agents/${projectUid}/task/${taskId}`);

          break;
        }
        case SpecialAgentWSType.PROGRESS: {
          updateProgress(data);
          break;
        }
        case SpecialAgentWSType.INPUT_REQUIRED: {
          updateProgress(data);
          setIsFeedbackRequired(true);
          break;
        }
        case SpecialAgentWSType.FINAL_RESULT: {
          updateProgress(data);
          break;
        }
        default: {
          break;
        }
      }
    };

    ws.current.onerror = (error) => {
      toast.error(`Error: ${error}`);
    };

    ws.current.onclose = () => {
      console.log('WebSocket connection closed');
    };
  };

  const sendFeedback = (data) => {
    if (ws.current && ws.current.readyState === WebSocket.OPEN) {
      ws.current.send(JSON.stringify(data));
      setIsLoading(true);
      setIsFeedbackRequired(false);
    } else {
      console.error('WebSocket is not connected');
      toast.error('Error: WebSocket is not connected');
    }
  };

  return { taskContents, isFeedbackRequired, isLoading, sendQuery, sendFeedback };
};
