import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';

import { SpecialAgents } from 'apis/SpecialAgents';
import { RootStateType } from 'store/store';
import { getFilePath } from 'core/helpers';

import ConfirmationModal from 'components/Modal/ConfirmationModal';

const AgentHubContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const agentsConfig = useSelector((state: RootStateType) => state.specialAgents.agentsConfig);

  const [isSaving, setIsSaving] = useState(false);
  const [confirmAgentId, setConfirmAgentId] = useState<null | number>(null);

  useEffect(() => {
    SpecialAgents.getSpecialAgentsConfig(dispatch);
  }, []);

  const items = agentsConfig.results || [];

  const handleConfirm = async () => {
    setIsSaving(true);

    const payload = {
      special_agent_config_id: confirmAgentId
    };
    const data = await SpecialAgents.hireSpecialAgent(payload);

    setConfirmAgentId(null);
    setIsSaving(false);

    if (data) {
      toast.success('Agent hired successfully');
      const projectUid = data.project?.uid;
      navigate(`/agents/${projectUid}/settings`);
    }
  };

  return (
    <div className="max-h-screen">
      <div className="container mx-auto py-12 max-w-[2000px]">
        <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-6">
          {items.map((agentConfig, index) => (
            <div
              key={`${agentConfig.type}-${index}`}
              className="p-6 rounded-xl shadow-lg overflow-hidden transition-transform duration-300 hover:transform hover:scale-105 border-[1px] border-[#2F3944]"
            >
              <div className="flex items-center justify-between mb-[22px]">
                <div className="image object-cover">
                  <img
                    width="32"
                    height="32"
                    src={getFilePath(agentConfig?.image?.file_path)}
                    alt=""
                  />
                </div>
              </div>

              <div className="mt-[12px]">
                <div className="">
                  <p className="agentName mb-[7px]">{agentConfig.name}</p>
                </div>
                <p
                  className="
          mt-[4px] text-sm text-[#8297AE] mb-6 text-ellipsis overflow-hidden display-[-webkit-box] line-clamp-2"
                >
                  {agentConfig.description}
                </p>
                <div className="flex items-center justify-between">
                  <div>{agentConfig.is_hired && <p>Hired</p>}</div>
                  <div>
                    <button
                      className="py-2 px-4 bg-[#2A2E37] rounded-xl"
                      onClick={() => setConfirmAgentId(agentConfig.id)}
                    >
                      Hire
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {confirmAgentId && (
        <ConfirmationModal
          title={'Confirm Hire'}
          onCancel={() => setConfirmAgentId(null)}
          onConfirm={() => handleConfirm()}
          isLoading={isSaving}
          description={'Are you sure you want to hire this agent?'}
        />
      )}
    </div>
  );
};

export default AgentHubContainer;
