import React from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';

import CustomModal from 'components/Modal/CustomModal';

type IFormInput = {
  name: string;
  description: string | null;
  is_human_in_loop: boolean;
  system_prompt: string | null;
  extra_data: string | null;
};

function validJson(str: string) {
  if (!str) {
    return [{}, true];
  }

  try {
    const json = JSON.parse(str);
    return [json, true];
  } catch (e) {
    return [{}, false];
  }
}

const AgentEditForm = ({ title, isOpen, onClose, handleFormSubmit, defaultValue = {} }: any) => {
  const extraData = defaultValue?.extra_data
    ? JSON.stringify(defaultValue?.extra_data, null, 4)
    : '';

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError
  } = useForm<IFormInput>({ defaultValues: { ...defaultValue, extra_data: extraData } });

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    const [extra_data, isValid] = validJson(data.extra_data as any);

    if (!isValid) {
      setError('extra_data', { type: 'custom', message: 'Value should be valid json' });
      return;
    }

    const payload: any = {
      name: data.name,
      description: data.description,
      is_human_in_loop: data.is_human_in_loop,
      system_prompt: data.system_prompt,
      extra_data
    };

    handleFormSubmit(payload);
  };

  return (
    <CustomModal title={title} isOpen={isOpen} onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex items-center rounded dark:hover:bg-gray-600">
          <label className="cursor-pointer p-1 w-full text-lg font-medium text-gray-200 rounded dark:text-gray-300">
            <input
              className="mr-1 w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              type="checkbox"
              {...register('is_human_in_loop', { required: false })}
            />
            Human In Loop
          </label>
        </div>
        <div className="md-4">
          <div className="flex flex-start">
            <label className="text-white block font-semibold mb-1 InputLabel mt-[10px]">Name</label>
          </div>
          <div className="relative">
            <input
              className="w-full bg-slate-700 border mt-[5px]  text-white Input"
              {...register('name', { required: true })}
              type="text"
            />
          </div>
          {errors.name && <span className="text-red-400">This field is required</span>}
        </div>
        <div className="md-4">
          <div className="flex flex-start">
            <label className="text-white block font-semibold mb-1 InputLabel mt-[10px]">
              Description (Optional)
            </label>
          </div>
          <div className="relative">
            <textarea
              className="w-full bg-slate-700 border mt-[5px]  text-white Input"
              {...register('description', { required: false })}
            />
          </div>
        </div>

        <div className="md-4">
          <div className="flex flex-start">
            <label className="text-white block font-semibold mb-1 InputLabel mt-[10px]">
              System Prompt (Optional)
            </label>
          </div>
          <div className="relative">
            <textarea
              className="w-full bg-slate-700 border mt-[5px]  text-white Input"
              {...register('system_prompt', { required: false })}
            />
          </div>
        </div>

        <div className="md-4">
          <div className="flex flex-start">
            <label className="text-white block font-semibold mb-1 InputLabel mt-[10px]">
              Extra Data (Optional)
            </label>
          </div>
          <div className="relative">
            <textarea
              className="w-full bg-slate-700 border mt-[5px]  text-white Input"
              {...register('extra_data', { required: false })}
            />
          </div>
          {errors.extra_data && <span className="text-red-400">{errors.extra_data?.message}</span>}
        </div>

        <input type="submit" className="submitBtn w-full mt-8 cursor-pointer" />
      </form>
    </CustomModal>
  );
};

export default AgentEditForm;
