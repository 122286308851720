import React from 'react';

import { Layout } from 'components/Layout/Layout';
import TopBar from 'components/Header/TopBar';
import Banner from 'components/General/Banner';
import AgentHubContainer from 'components/AgentHub/AgentHubContainer';

const AgentHub = () => {
  return (
    <Layout>
      <div className="p-[30px] relative pt-[80px]">
        <TopBar />
        <Banner />
        <AgentHubContainer />
      </div>
    </Layout>
  );
};

export default AgentHub;
