import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';

import { getFilePath } from 'core/helpers';
import { getRefreshToken } from 'core/helpers/token';
import { Agents } from 'apis/Agents';
import { setAIAgents, setSearchAgents } from 'store/reducers/agentsSlice';
import { AgentEnum, DEFAULT_LIMIT } from 'core/constants';
import { setIsNewTask, setSpecialAgents } from 'store/reducers/specialAgentsSlice';

import { Divider } from '../Divider/Divider';
import ProfileSidebar from 'components/ProfileSettings/ProfileSidebar';
import MessageSidebar from 'components/MessageSidebar/MessageSidebar';
import NavSupportCard from './NavSupportCard';

import './Layout.css';
import { RootStateType } from 'store/store';
import TopBar from 'components/Header/TopBar';

const messageUrlPattern = /^\/agents\/[^/]+\/chat-widget\/[^/]+\/customer-chats$/;

export const Layout = ({
  children,
  messages,
  showMessages,
  tasks,
  showTasks,
  showTopBar = false
}: any) => {
  const dispatch = useDispatch();

  const location = useLocation();
  const accessToken = useMemo(() => getRefreshToken(), []);

  const aiAgents = useSelector((state: any) => state.agent.aiAgents);
  const searchAgents = useSelector((state: any) => state.agent.searchAgents);
  const specialAgents = useSelector((state: RootStateType) => state.specialAgents.subAgents);

  const isMessageUrl = useMemo(() => {
    if (messageUrlPattern.test(location.pathname)) {
      return true;
    }
    return false;
  }, [location]);

  const loadAgents = async (limit: number, project_type: string, setStoreValue: any) => {
    const data = await Agents.getAgents({ limit, project_type });
    if (data) {
      dispatch(setStoreValue(data.results));
    }
  };

  useEffect(() => {
    if (accessToken && !isMessageUrl) {
      loadAgents(DEFAULT_LIMIT, AgentEnum.AGENT.toString(), setAIAgents);
      loadAgents(DEFAULT_LIMIT, AgentEnum.RAG.toString(), setSearchAgents);
      loadAgents(DEFAULT_LIMIT, AgentEnum.SPECIAL_AGENT.toString(), setSpecialAgents);
    }
  }, [isMessageUrl]);

  const renderSideBar = () => {
    if (location.pathname === '/profile-settings') {
      return (
        <div className="">
          <ProfileSidebar />
        </div>
      );
    } else if (isMessageUrl) {
      return (
        <div className="">
          <MessageSidebar />
        </div>
      );
    } else {
      return (
        <AgentSideBar
          aiAgents={aiAgents}
          searchAgents={searchAgents}
          specialAgents={specialAgents}
        />
      );
    }
  };

  return (
    <div
      className="text-white relative"
      style={{
        background: 'var(--Neutral-9, #121821)',
        display: 'flex',
        height: '100vh'
      }}
    >
      {/* sidebar start */}
      <div className="sidebar w-[296px] custom-scroll">
        <div className="flex justify-center items-center px-6 py-[21px]">
          <Link to={'/agents'}>
            <img className="w-[150px]" src="/brand-logo/logo.svg" alt="" />
          </Link>
        </div>
        <div className="flex px-6 py-[15px] bg-[#2f333d]">
          <Link to={'/agent-hub'} className="flex">
            <img width={20} className="w-[20px] h-[20px]" src="/icons/network.png" />
            <p className="text-[16px] text-white font-semibold pl-3 uppercase">Agent Hub</p>
          </Link>
        </div>
        <Divider />
        {/* Sidebar content */}
        {renderSideBar()}

        {showMessages && <SidebarMessage messages={messages} />}
        {showTasks && <SidebarTask tasks={tasks} />}

        <div className="fixed bottom-0 left-0 w-[296px] bg-[#191f28]">
          <NavSupportCard />
        </div>
        {/* End of sidebar content */}
      </div>
      {/* sidebar end */}

      {/* Main Content and Input fields */}
      <div className="pl-64 main-content custom-scroll">
        {showTopBar && <TopBar />}
        {children}
      </div>
      {/* End of Main Content and Input fields */}
    </div>
  );
};

const SidebarMessage = ({ messages }: any) => {
  const { projectUid } = useParams();
  if (!messages) return null;
  return (
    <div className="flex justify-center items-start flex-col mt-6 pl-4">
      <div className="flex gap-4  items-center mt-6">
        <a href={`/agents/${projectUid}/chat/`}>
          <p className="CommunityText">Chat History</p>
        </a>
      </div>

      <div className="flex mt-4 gap-1">
        <div style={{ background: 'var(--Neutral-8, #191f28)', width: '1px' }}></div>
        <div className="scrollable">
          {messages.map((message: any) => {
            return (
              <Link key={`message-${message.id}`} to={`/agents/${projectUid}/chat/${message.id}`}>
                <p className="projects">{message.question}</p>
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const SidebarTask = ({ tasks }: any) => {
  const { projectUid } = useParams();
  const dispatch = useDispatch();

  if (!tasks) return null;

  return (
    <div className="flex justify-center items-start flex-col mt-6 pl-4">
      <div className="flex gap-4  items-center mt-6">
        <a href={`/special-agents/${projectUid}/task/`}>
          <p className="CommunityText">Task History</p>
        </a>
      </div>

      <div className="flex mt-4 gap-1">
        <div style={{ background: 'var(--Neutral-8, #191f28)', width: '1px' }}></div>
        <div className="scrollable">
          {tasks.map((task: any) => {
            return (
              <Link
                key={`task-list-${task.id}`}
                to={`/special-agents/${projectUid}/task/${task.id}`}
                onClick={() => dispatch(setIsNewTask(false))}
              >
                <p className="projects">{task.title}</p>
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const AgentSideBar = ({ aiAgents, searchAgents, specialAgents }: any) => {
  return (
    <div className=" flex justify-center items-start flex-col mt-2 pl-4">
      <div className="flex gap-4 items-center">
        <Link to={'/agents'}>
          <p className="text-[13px] font-semibold text-[#637B94] pl-3 uppercase">Special Agents</p>
        </Link>
      </div>
      <div className="mt-2 mb-8 w-full pr-4">
        {specialAgents.map((agent: any) => {
          return (
            <Link
              key={`agent-${agent.uid}`}
              to={`/special-agents/${agent.uid}/task`}
              className="flex gap-3 items-center mb-1.5 py-3 px-[10px] rounded-lg hover:bg-[#222831]"
            >
              <img
                className=""
                width="24"
                height="24"
                src={getFilePath(agent?.image?.file_path, '/user/User.png')}
                alt=""
              />
              <p key={agent.uid} className="text-[#F6F7F9] text-base font-semibold line-clamp-1">
                {agent.name}
              </p>
            </Link>
          );
        })}
      </div>

      <div className="flex gap-4 items-center">
        <Link to={'/agents'}>
          <p className="text-[13px] font-semibold text-[#637B94] pl-3 uppercase">Simple Agents</p>
        </Link>
      </div>
      <div className="mt-2 mb-8 w-full pr-4">
        {searchAgents.map((agent: any) => {
          return (
            <Link
              key={`agent-${agent.uid}`}
              to={`/agents/${agent.uid}/chat`}
              className="flex gap-3 items-center mb-1.5 py-3 px-[10px] rounded-lg hover:bg-[#222831]"
            >
              <img
                className=""
                width="24"
                height="24"
                src={getFilePath(agent?.image?.file_path, '/user/User.png')}
                alt=""
              />
              <p key={agent.uid} className="text-[#F6F7F9] text-base font-semibold line-clamp-1">
                {agent.name}
              </p>
            </Link>
          );
        })}
      </div>

      <div className="flex gap-4  items-center mt-6">
        <Link to={'/agents'}>
          <p className="text-[13px] font-semibold text-[#637B94] pl-3 uppercase">Advance Agents</p>
        </Link>
      </div>

      <div className="mt-4 w-full pr-4 pb-[60px]">
        {aiAgents.map((agent: any) => {
          return (
            <Link
              key={`agent-${agent.uid}`}
              to={`/agents/${agent.uid}/chat`}
              className="flex gap-3 items-center mb-1.5 py-3 px-[10px] rounded-lg hover:bg-[#222831]"
            >
              <img
                className=""
                width="24"
                height="24"
                src={getFilePath(agent?.image?.file_path, '/user/User.png')}
                alt=""
              />
              <p key={agent.uid} className="text-[#F6F7F9] text-base font-semibold line-clamp-1">
                {agent.name}
              </p>
            </Link>
          );
        })}
      </div>
    </div>
  );
};
