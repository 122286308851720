import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useSpecialAgentWS } from 'websocket/SpecialAgentWSHandler';
import { ISpecialAgentDetails, ITaskContentOut, ITaskDetails } from 'types/specialAgent';
import { setIsNewTask } from 'store/reducers/specialAgentsSlice';

import SpecialAgentInputBox from './InputBox';
import TaskContent from './TaskContent';
import Loader from 'components/General/Loader';

import './TaskContainer.scss';

interface IProps {
  specialAgentDetails: ISpecialAgentDetails | undefined | null;
  projectUid: string | undefined;
  taskId: string | null | undefined;
  taskDetails: ITaskDetails | null;
  setTaskDetails: any;
}

const TaskContainer = ({
  projectUid,
  specialAgentDetails,
  taskId,
  taskDetails,
  setTaskDetails
}: IProps) => {
  const dispatch = useDispatch();
  const [askedQuery, setAskedQuery] = useState('');

  const { taskContents, sendQuery, sendFeedback, isFeedbackRequired, isLoading } =
    useSpecialAgentWS(projectUid);

  const specialAgentTitle = specialAgentDetails?.name;
  const existingTaskTitle = taskDetails?.title || '';
  const isNewMessage = taskId && existingTaskTitle ? false : true;
  const taskTitle = isNewMessage ? askedQuery : existingTaskTitle;

  const calculatedTaskContents = useMemo(() => {
    const taskContentList: ITaskContentOut[] = isNewMessage
      ? taskContents
      : (taskDetails?.task_contents as any);

    if (!taskContentList?.length) return [];

    const results: ITaskContentOut[] = [];

    for (let i = 0; i < taskContentList.length - 1; i++) {
      if (taskContentList[i].sub_agent_id == taskContentList[i + 1].sub_agent_id) {
        // If the current task content agent is same as next one then we have duplication.
        // If there is an duplication then we will take last one.
        continue;
      }
      results.push(taskContentList[i]);
    }

    // Add the last item
    results.push(taskContentList[taskContentList.length - 1]);

    return results;
  }, [taskContents, taskDetails, isNewMessage]);

  const getSubAgentById = (subAgentId: number | null) => {
    if (!subAgentId) return null;

    if (!specialAgentDetails?.sub_agents?.length) return null;

    const subAgent = specialAgentDetails?.sub_agents.find((item) => item.id == subAgentId);

    return subAgent;
  };

  const onSubmit = async (query: string, queryFile?: any) => {
    dispatch(setIsNewTask(true));
    sendQuery(query, queryFile);
    setAskedQuery(query);
    setTaskDetails(null);
  };

  const renderAskedQuery = () => {
    if (!taskTitle) return null;

    return (
      <div className="p-4 mb-6 bg-[#191F28] rounded-md">
        <p className="text-lg">{taskTitle}</p>
      </div>
    );
  };

  const renderTaskContents = () => {
    if (!calculatedTaskContents) return null;

    return calculatedTaskContents.map((taskContent: ITaskContentOut, index: number) => {
      const lastMessage = calculatedTaskContents.length - 1 === index;

      if (!taskContent.content) return null;

      const subAgent = getSubAgentById(taskContent.sub_agent_id);

      return (
        <TaskContent
          key={`task-content--${taskContent.task_content_id}-${index}`}
          showFeedback={isFeedbackRequired && lastMessage}
          sendFeedback={sendFeedback}
          title={subAgent?.name}
          message={taskContent.content}
        />
      );
    });
  };

  const renderLandingContent = () => {
    return (
      <div className="flex flex-col items-center justify-center h-full">
        <img className="max-w-md mx-auto text-center" src="/message/laptop-with-chat.svg" />
        <p className="text-center mb-2 text-lg">{specialAgentTitle}</p>
        <p className="text-center max-w-[650px]">
          Don{"'"}t forget to write down the topic of your content. You can also give a direction
          and additional information in the type area.
        </p>
      </div>
    );
  };

  const renderTaskContent = () => {
    return (
      <>
        {renderAskedQuery()}
        {renderTaskContents()}
        {isLoading && <Loader text={`${specialAgentTitle} Thinking`} />}
      </>
    );
  };

  return (
    <div className="chat-container">
      <div className="flex-grow overflow-y-auto">
        {taskTitle ? renderTaskContent() : renderLandingContent()}
      </div>
      <SpecialAgentInputBox onSubmit={onSubmit} />
    </div>
  );
};

export default TaskContainer;
