import { Dispatch } from '@reduxjs/toolkit';

import { API, toastApiError } from './api';
import { setAgentsConfig } from 'store/reducers/specialAgentsSlice';
import { API_URLS, buildUrl } from 'core/endpoints';

export class SpecialAgents {
  static async getSpecialAgentsConfig(dispatch: Dispatch) {
    try {
      const url = buildUrl(API_URLS.specialAgentsConfig);
      const res = await API.get(url);
      dispatch(setAgentsConfig(res.data));

      return res.data;
    } catch (error) {
      toastApiError(error);
      return null;
    }
  }

  static async hireSpecialAgent(payload: any) {
    try {
      const url = buildUrl(API_URLS.specialAgents);
      const res = await API.post(url, payload);

      return res.data;
    } catch (error) {
      toastApiError(error);
      return null;
    }
  }

  static async getSpecialAgentDetails(projectUid: string) {
    try {
      const url = buildUrl(API_URLS.specialAgentDetails, { projectUid });
      const res = await API.get(url);

      return res.data;
    } catch (error) {
      toastApiError(error);
      return null;
    }
  }

  static async getAgents(projectUid: string) {
    try {
      const url = buildUrl(API_URLS.subAgents, { projectUid });
      const res = await API.get(url);

      return res.data;
    } catch (error) {
      toastApiError(error);
      return null;
    }
  }

  static async updateAgents(projectUid: string, subAgentId: number, payload: any) {
    try {
      const url = buildUrl(API_URLS.subAgentDetails, { projectUid, subAgentId });
      const res = await API.put(url, payload);

      return res.data;
    } catch (error) {
      toastApiError(error);
      return null;
    }
  }

  static async getTasks(projectUid: string, queryParams: any) {
    try {
      const url = buildUrl(API_URLS.specialAgentTasks, { projectUid }, queryParams);
      const res = await API.get(url);

      return res.data;
    } catch (error) {
      toastApiError(error);
      return null;
    }
  }

  static async getTaskDetails(projectUid: string, taskId: number) {
    try {
      const url = buildUrl(API_URLS.specialAgentTaskDetails, { projectUid, taskId });
      const res = await API.get(url);

      return res.data;
    } catch (error) {
      toastApiError(error);
      return null;
    }
  }
}
