import { GoogleLogin } from '@react-oauth/google';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Auth } from '../../apis/Auth';
import { setTokens } from 'core/helpers/token';

const GoogleAuthButton = () => {
  const navigate = useNavigate();

  const handleCredential = async (credentialResponse: any) => {
    const { credential } = credentialResponse;

    const data = await Auth.googleLogin({ token: credential });
    if (data) {
      const { access_token, refresh_token, ephemeral_token } = data;
      setTokens(access_token, refresh_token, ephemeral_token);
      navigate('/');
    }
  };

  return (
    <div className="flex justify-center mt-4">
      <GoogleLogin
        onSuccess={handleCredential}
        onError={() => {
          toast.warning('Someting went wrong');
        }}
      />
    </div>
  );
};

export default GoogleAuthButton;
