import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { SpecialAgents } from 'apis/SpecialAgents';
import EllipsisDropdown from 'components/Utility/EllipsisDropdown';
import AgentEditForm from './AgentEditForm';

const SubAgent = () => {
  const { projectUid } = useParams();

  const [agents, setAgents] = useState<any[]>([]);
  const [activeAgent, setActiveAgent] = useState<any>(null);

  useEffect(() => {
    loadAgents();
  }, [projectUid]);

  const loadAgents = async () => {
    if (!projectUid) return;
    const data = await SpecialAgents.getAgents(projectUid);

    if (data) {
      setAgents(data.results || []);
    }
  };

  const handleFormSubmit = async (payload: any) => {
    const agentId = activeAgent?.id;

    if (!projectUid || !agentId) {
      return;
    }

    const data = await SpecialAgents.updateAgents(projectUid, agentId, payload);

    if (data) {
      loadAgents();
      setActiveAgent(null);
    }
  };

  return (
    <div className="p-8 border-[1px] border-solid border-[#222831] rounded-[16px] even-child-m-8 max-w-[900px]">
      {agents.map((agent) => {
        return (
          <div
            key={`agent-${agent.id}`}
            className="p-6 rounded-[8px] border-[1px] border-[#2F3944] [&:not(:last-child)]:mb-2"
          >
            <div className="flex items-center justify-between mb-[22px]">
              <div>{agent.name}</div>
              <div className="flex justify-between">
                <EllipsisDropdown>
                  <ul>
                    <li
                      className="hover:bg-gray-600 cursor-pointer"
                      style={{
                        borderBottom: '1px solid #2F3944'
                      }}
                    >
                      <div
                        className="flex gap-4 items-center p-[16px]"
                        onClick={() => setActiveAgent(agent)}
                      >
                        <img className="w-[16px] h-[16px]" src="/icons/edit-icon.svg" alt="" />
                        <button>Edit</button>
                      </div>
                    </li>
                  </ul>
                </EllipsisDropdown>
              </div>
            </div>
            <div className="mt-4">
              Description:{' '}
              <span className="mt-[4px] text-sm text-[#8297AE] mb-6 text-ellipsis overflow-hidden">
                {agent.description}
              </span>
            </div>
            <div className="mt-4">
              System Prompt:{' '}
              <span className="mt-[4px] text-sm text-[#8297AE] mb-6 text-ellipsis overflow-hidden">
                {agent.system_prompt}
              </span>
            </div>
          </div>
        );
      })}

      {activeAgent && (
        <AgentEditForm
          title="Edit Agent"
          isOpen={!!activeAgent}
          onClose={() => setActiveAgent(null)}
          handleFormSubmit={handleFormSubmit}
          defaultValue={activeAgent}
        />
      )}
    </div>
  );
};

export default SubAgent;
