import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { getAccessTokenThatMinValidForNMinutes } from 'apis/api';
import { buildUrl, SPECIAL_AGENT_WS_URL } from 'core/endpoints';

import MarkdownRenderer from 'components/MarkdownRenderer';

import './FacebookAgentOld.scss';

const TOKEN_PADDING_MINUTES = 5;

const getSpecialAgentUlr = async (p_uid: string) => {
  const { ephemeralToken } = await getAccessTokenThatMinValidForNMinutes(TOKEN_PADDING_MINUTES);

  const queryParams = { token: ephemeralToken, p_uid };

  const url = buildUrl(SPECIAL_AGENT_WS_URL, {}, queryParams);

  return url;
};

export default function FacebookAgentOld() {
  const ws = useRef<WebSocket | null>(null);
  const { projectUid } = useParams();
  const [query, setQuery] = useState('');
  const [progress, setProgress] = useState<string[]>([]);
  const [responseRequired, setResponseRequired] = useState(false);

  useEffect(() => {
    return () => {
      // Clean up WebSocket connection if component unmounts
      if (ws.current) {
        ws.current.close();
      }
    };
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setProgress([]); // Clear previous progress

    if (!projectUid) {
      toast.error('Invalid project id');
      return;
    }

    const url = await getSpecialAgentUlr(projectUid);
    ws.current = new WebSocket(url);

    ws.current.onopen = () => {
      if (ws.current) {
        ws.current.send(JSON.stringify({ question: query }));
      } else {
        toast.warning('Someting went wrong.');
      }
    };

    ws.current.onmessage = (event) => {
      const data = JSON.parse(event.data);

      if (data.type === 'progress') {
        setProgress((prev) => [...prev, data.payload]);
      } else if (data.type === 'input_required') {
        setProgress((prev) => [...prev, data.payload]);
        setResponseRequired(true);
      } else if (data.type === 'final_result') {
        setProgress((prev) => [...prev, `Final result: ${data.payload}`]);
      }
    };

    ws.current.onerror = (error) => {
      console.error('WebSocket Error:', error);
      setProgress((prev) => [...prev, `Error: ${error}`]);
    };

    ws.current.onclose = () => {
      setProgress((prev) => [...prev, 'Connection closed']);
    };

    // Store the ws.current in the window object for cleanup
  };

  const handleResponse = (response: 'yes' | 'no') => {
    if (ws.current && ws.current.readyState === WebSocket.OPEN) {
      ws.current.send(JSON.stringify({ response }));
      setResponseRequired(false);
    } else {
      console.error('WebSocket is not connected');
      setProgress((prev) => [...prev, 'Error: WebSocket is not connected']);
    }
  };

  const renderProgress = (progressList: any) => {
    return progressList.map((message, index) => (
      <div key={index} className="message">
        {
          <div style={{ color: 'black', whiteSpace: 'pre-wrap' }}>
            <MarkdownRenderer content={message} />
          </div>
        }

        {responseRequired && index === progress.length - 1 && (
          <div className="responseButtons">
            <p style={{ color: 'black' }}>Does this look like enough to write a report?</p>
            <button onClick={() => handleResponse('yes')} className="button">
              Yes
            </button>
            <button onClick={() => handleResponse('no')} className="button">
              No
            </button>
          </div>
        )}
      </div>
    ));
  };

  return (
    <div className="agent-container">
      <form onSubmit={handleSubmit} className="form">
        <input
          type="text"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          placeholder="Enter your query"
          className="input"
        />
        <button type="submit" className="button">
          Query
        </button>
      </form>
      <div id="progress" className="progress">
        <h2>Progress:</h2>
        {renderProgress(progress)}
      </div>
    </div>
  );
}
