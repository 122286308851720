import { Tab } from '@headlessui/react';
import axios from 'axios';
import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ');
}

const FileUploadModal = () => {
  const [selectedFile, setSelectedFile] = useState(null);

  const onDrop = (acceptedFiles: any) => {
    setSelectedFile(acceptedFiles[0]);
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const handleUpload = async () => {
    if (!selectedFile) return;

    const formData = new FormData();
    formData.append('file', selectedFile);

    try {
      const response = await axios.post('/upload-image', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      return response.data;
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-gray-900 text-white p-8 rounded-lg shadow-lg w-[450px]">
        <h2 className="text-xl font-bold mb-4">Ingest File</h2>
        <Tab.Group>
          <Tab.List className="flex p-1 space-x-1 rounded-xl">
            <Tab
              style={{ borderBottom: '2px solid var(--Gradient, #5FBCFF)' }}
              className={({ selected }) =>
                classNames(
                  'w-full py-2.5 text-sm leading-5 font-medium text-blue-700',
                  '',
                  selected ? '' : 'text-blue-100 hover:bg-white/[0.12] hover:text-white'
                )
              }
            >
              File Upload
            </Tab>
            <Tab
              className={({ selected }) =>
                classNames(
                  'w-full py-2.5 text-sm leading-5 font-medium text-blue-700 rounded-lg',
                  'focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60',
                  selected
                    ? 'bg-white shadow'
                    : 'text-blue-100 hover:bg-white/[0.12] hover:text-white'
                )
              }
            >
              URL
            </Tab>
            <Tab
              className={({ selected }) =>
                classNames(
                  'w-full py-2.5 text-sm leading-5 font-medium text-blue-700 rounded-lg',
                  'focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60',
                  selected
                    ? 'bg-white shadow'
                    : 'text-blue-100 hover:bg-white/[0.12] hover:text-white'
                )
              }
            >
              Text Message
            </Tab>
          </Tab.List>
          <Tab.Panels className="mt-2">
            <Tab.Panel className="p-3">
              <div
                {...getRootProps({
                  className:
                    'border-2 border-dashed border-gray-500 p-6 rounded-lg cursor-pointer focus:outline-none'
                })}
              >
                <input {...getInputProps()} />
                <div className="text-center">
                  <button className="bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-md inline-flex items-center">
                    <svg
                      className="w-5 h-5 mr-2"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 9.293a1 1 0 010 1.414l-6 6a1 1 0 01-1.414 0l-6-6a1 1 0 011.414-1.414L9 13.586V2a1 1 0 112 0v11.586l4.293-4.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                    Upload File
                  </button>
                  <p className="mt-2 text-gray-400">
                    Please upload your file. Accepted file types include PDF, XML, Word, etc.
                    Maximum file size is 20 MB.
                  </p>
                </div>
              </div>
            </Tab.Panel>
            <Tab.Panel className="p-3">
              <p>URL input form (not implemented)</p>
            </Tab.Panel>
            <Tab.Panel className="p-3">
              <p>Text message input form (not implemented)</p>
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
        <div className="flex justify-end mt-4">
          <button className="bg-gray-600 hover:bg-gray-700 text-white py-2 px-4 rounded-md mr-2">
            Cancel
          </button>
          <button
            className="bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-md"
            onClick={handleUpload}
          >
            Ingest Now
          </button>
        </div>
      </div>
    </div>
  );
};

export default FileUploadModal;
