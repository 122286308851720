import React, { useState, ChangeEvent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Agents } from 'apis/Agents';
import { setAgentsTools } from 'store/reducers/agentsSlice';
import { ITools, IToolsSpec } from 'types';

const Tools: React.FC = () => {
  const { projectUid } = useParams();
  const dispatch = useDispatch();

  const [selectedTools, setSelectedTools] = useState<number[]>([]);

  const allTools: IToolsSpec[] = useSelector((state: any) => state.agent?.allTools);

  const fetchAllTools = async () => {
    const data = await Agents.getAllTools({ limit: 100 });
    if (data) {
      dispatch(setAgentsTools(data));
    }
  };

  useEffect(() => {
    if (allTools) {
      fetchAllTools();
    }
  }, []);

  const updateAgentTools = (toolsSpecList: IToolsSpec[]) => {
    const toolsIds = toolsSpecList.reduce((toolsIds: number[], toolsSpec: IToolsSpec) => {
      const eachToolsIds = toolsSpec.tools.map((tools: ITools) => tools.id);
      return [...toolsIds, ...eachToolsIds];
    }, []);

    setSelectedTools(toolsIds);
  };

  const fetchProjectTools = async (projectUid) => {
    const data = await Agents.getProjectTools(projectUid);
    if (data) {
      updateAgentTools(data);
    }
  };

  useEffect(() => {
    if (projectUid) {
      fetchProjectTools(projectUid);
    }
  }, [projectUid]);

  const handleToolsSpecCheckboxChange = (toolsSpec: IToolsSpec, isChecked: boolean) => {
    const toolsIdsOfASpec = toolsSpec.tools.map((tools: ITools) => tools.id);
    setSelectedTools((prevSelectedTools) => {
      if (isChecked) {
        return Array.from(new Set([...prevSelectedTools, ...toolsIdsOfASpec]));
      } else {
        return prevSelectedTools.filter((id) => !toolsIdsOfASpec.includes(id));
      }
    });
  };

  const handleToolsCheckboxChange = (toolsId: number) => {
    setSelectedTools((prevSelectedTools) =>
      prevSelectedTools.includes(toolsId)
        ? prevSelectedTools.filter((id) => id !== toolsId)
        : [...prevSelectedTools, toolsId]
    );
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!projectUid) {
      alert('Agent id is missing');
      return;
    }

    const payload = { tools_ids: selectedTools };

    const data = await Agents.updateProjectTools(projectUid, payload);
    if (data) {
      updateAgentTools(data);
    }
  };

  return (
    <div className="p-8 border-[1px] border-solid border-[#222831] rounded-[16px] w-[620px]">
      <div className="flex items-center justify-between">
        <h1>Agent Tools</h1>
        <div>
          <button
            onClick={handleSubmit}
            className="btn btn-item go-back-btn text-base font-semibold text-center p-3 rounded-[12px] basis-1/2 bg-[#323C48] text-[#8297AE]"
          >
            Save
          </button>
        </div>
      </div>
      <div>
        {allTools.map((toolsSpec: IToolsSpec) => (
          <div key={toolsSpec.id}>
            <div className="flex items-center rounded hover:bg-gray-100 dark:hover:bg-gray-600">
              <label className="cursor-pointer p-1 w-full ms-2 text-lg font-medium text-gray-900 rounded dark:text-gray-300">
                <input
                  className="mr-1 w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                  type="checkbox"
                  checked={toolsSpec.tools.every((tools: ITools) =>
                    selectedTools.includes(tools.id)
                  )}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleToolsSpecCheckboxChange(toolsSpec, e.target.checked)
                  }
                />
                {toolsSpec.name}
              </label>
            </div>
            <div className="ml-6">
              {toolsSpec.tools.map((tools) => (
                <div
                  key={tools.id}
                  className="flex items-center rounded hover:bg-gray-100 dark:hover:bg-gray-600"
                >
                  <label className="cursor-pointer p-1 w-full ms-2 text-base font-medium text-gray-900 rounded dark:text-gray-300">
                    <input
                      className="mr-1 w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                      type="checkbox"
                      checked={selectedTools.includes(tools.id)}
                      onChange={() => handleToolsCheckboxChange(tools.id)}
                    />
                    {tools.name}
                  </label>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Tools;
