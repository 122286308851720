import React, { useState } from 'react';

import AttachFile from 'icons/AttachFile';
import { fileToBase64, formatFileSize } from 'core/helpers';

import Cross from 'icons/Cross';
import GeneralFile from 'icons/GeneralFile';

const PLACEHOLDER_TEXT = 'Ask NeuWark a question...';

export default function SpecialAgentInputBox({ onSubmit }: any) {
  const [query, setQuery] = useState('');
  const [queryFile, setQueryFile] = useState<any>(null);

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setQueryFile(file);
    }
  };

  const removeFile = () => {
    setQueryFile(null);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setQuery(e.target.value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      if (query && query.trim()) {
        handleSubmit();
      }
    }
  };

  const handleSubmit = async () => {
    let base64File: any = null;
    if (queryFile) {
      base64File = await fileToBase64(queryFile);
    }
    onSubmit(query, base64File);

    setQuery('');
    setQueryFile(null);
  };

  const showSendButtion = () => {
    return query || queryFile;
  };

  return (
    <div className="flex items-center justify-center mt-2">
      <div className="w-full max-w-2xl">
        <div className="flex flex-col bg-[#191F28] border border-[#333] rounded-lg overflow-hidden">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
            className="relative"
          >
            {queryFile && (
              <div className="px-4 pt-3">
                <div className="inline-flex items-center gap-2 bg-[#252525] rounded-md py-1 px-2 text-sm text-gray-300">
                  <GeneralFile />
                  <span>{queryFile.name}</span>
                  <span className="text-gray-500">{formatFileSize(queryFile.size)}</span>
                  <button onClick={removeFile} className="ml-1 text-gray-500 hover:text-gray-300">
                    <Cross />
                  </button>
                </div>
              </div>
            )}
            <textarea
              value={query}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              placeholder={PLACEHOLDER_TEXT}
              className="w-full min-h-[100px] resize-none bg-transparent text-gray-300 placeholder:text-gray-500 p-4 focus:outline-none pr-16"
            />
            {showSendButtion() && (
              <button className="absolute flex justify-center items-center top-[16px] right-[16px]">
                <img width="36" src="/message/chat-message.svg" alt="Icon" />
              </button>
            )}
          </form>
          <div className="flex items-center gap-2 px-4 pb-2 border-none">
            <label className="text-gray-500 hover:text-gray-400 transition-colors p-1 cursor-pointer">
              <AttachFile />
              <input type="file" className="hidden" onChange={onFileChange} />
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}
