import MarkdownRenderer from 'components/MarkdownRenderer';
import CustomModal from 'components/Modal/CustomModal';
import React, { useEffect, useState } from 'react';

interface IProps {
  isOpen: boolean;
  onClose: any;
  sendFeedback: any;
  content: string;
}

const TaskEditModal = (props: IProps) => {
  const { isOpen, onClose, content, sendFeedback } = props;

  const [editedContent, setEditedContent] = useState(content);

  useEffect(() => {
    setEditedContent(content);
  }, [content]);

  const handleSave = () => {
    sendFeedback({ response: 'custom', content: editedContent });
    onClose(false);
  };

  return (
    <CustomModal
      title="Edit Content"
      className="modal-big"
      isOpen={isOpen}
      onClose={() => onClose(false)}
    >
      <div className="flex flex-col">
        <div className="flex min-h-[300px]">
          <div className="flex-1 p-4">
            <textarea
              value={editedContent}
              onChange={(e) => setEditedContent(e.target.value)}
              placeholder="Ask Question..."
              className="w-full h-full bg-[#191F28] rounded-lg pl-4 pr-12 py-6 resize-none min-h-[300px] custom-scroll focus:outline-none border border-gray-600"
              rows={1}
            />
          </div>

          {/* Divider */}
          <div className="w-px bg-gray-500 self-stretch mx-2"></div>

          <div className="flex-1 p-4 overflow-auto">
            <MarkdownRenderer content={editedContent} />
          </div>
        </div>

        <div className="mt-4 flex justify-end">
          <div className="button-area flex items-center justify-center gap-[16px]">
            <button
              className="px-4 py-2 bg-gray-600 text-white rounded-md hover:bg-gray-700 transition-colors"
              onClick={() => onClose(false)}
            >
              Cancel
            </button>
            <button
              className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors"
              onClick={handleSave}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </CustomModal>
  );
};

export default TaskEditModal;
