import React from 'react';

const Banner = () => {
  return (
    <img
      className="w-full mb-4 banner-image"
      width="1080"
      height="160"
      src="/agent/banner.png"
      alt="Banner image"
    />
  );
};

export default Banner;
