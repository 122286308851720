import SpinnerLoader from 'components/Utility/SpinnerLoader';
import React from 'react';

const DEFAULT_DESCRIPTION = 'Are you sure?';

interface IProps {
  title: string;
  onCancel: any;
  onConfirm: any;
  isLoading?: boolean;
  description?: string | null;
  cancelText?: string | null;
  confirmText?: string | null;
}

const ConfirmationModal = (props: IProps) => {
  const {
    onCancel,
    onConfirm,
    title,
    isLoading = false,
    description = null,
    cancelText = null,
    confirmText = null
  } = props;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-gray-800 p-6 rounded-lg max-w-sm mx-4">
        <h4 className="text-white text-lg font-semibold mb-4">{title}</h4>
        <p className="text-gray-400 mb-6">{description || DEFAULT_DESCRIPTION}</p>
        <SpinnerLoader loading={isLoading} icon_size={45}>
          <div className="flex gap-3">
            <button
              onClick={onCancel}
              className="px-4 py-2 rounded-lg text-sm bg-gray-700 text-white hover:bg-gray-600"
            >
              {cancelText || 'Cancel'}
            </button>
            <button
              onClick={onConfirm}
              className="px-4 py-2 rounded-lg text-sm bg-orange-500 text-white hover:bg-orange-600"
            >
              {confirmText || 'Confirm'}
            </button>
          </div>
        </SpinnerLoader>
      </div>
    </div>
  );
};

export default ConfirmationModal;
