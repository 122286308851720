import { createSlice } from '@reduxjs/toolkit';

import {
  ISpecialAgentDetails,
  SpecialAgentsConfigResponse,
  ISubAgent,
  ITaskMin
} from 'types/specialAgent';

interface ITaskObject {
  cursor: number | null;
  results: ITaskMin[];
}

interface ISpecialAgentStore {
  // messages under agent projectUid
  agentsConfig: SpecialAgentsConfigResponse;
  subAgents: ISubAgent[];
  specialAgentDetailsByProjectUid: {
    [key: string]: ISpecialAgentDetails;
  };
  isNewTask: boolean;
  projectTasks: {
    [key: string]: ITaskObject;
  };
}

const initialState: ISpecialAgentStore = {
  agentsConfig: {
    after: 1,
    results: []
  },
  subAgents: [],
  specialAgentDetailsByProjectUid: {},
  isNewTask: false,
  projectTasks: {}
};

const specialAgentsSlice = createSlice({
  name: 'specialAgents',
  initialState,
  reducers: {
    setAgentsConfig: (state: ISpecialAgentStore, action) => {
      state.agentsConfig = action.payload;
    },
    setSpecialAgents: (state: ISpecialAgentStore, action) => {
      state.subAgents = action.payload;
    },
    setSpecialAgentDetails: (state: ISpecialAgentStore, action) => {
      const projectUid = action.payload.projectUid;
      if (!projectUid) return;

      const specialAgentDetails = action.payload.specialAgentDetails;

      state.specialAgentDetailsByProjectUid[projectUid] = specialAgentDetails;
    },
    setIsNewTask: (state: ISpecialAgentStore, action) => {
      state.isNewTask = action.payload;
    },
    setProjectTasks: (state: ISpecialAgentStore, action) => {
      const projectUid = action.payload.projectUid;
      const cursor = action.payload?.after;
      const results = action.payload?.results || [];

      if (state.projectTasks[projectUid] == null) {
        state.projectTasks[projectUid] = {
          results: [],
          cursor: null
        };
      }

      const projectTaskObj = { ...state.projectTasks[projectUid] };
      if (projectTaskObj.cursor === cursor) {
        // This is a redundant call nothing need to be updated
        return;
      }

      projectTaskObj.cursor = cursor;
      projectTaskObj.results = [...projectTaskObj.results, ...results];

      state.projectTasks[projectUid] = projectTaskObj;
    }
  }
});

export const {
  setAgentsConfig,
  setSpecialAgents,
  setSpecialAgentDetails,
  setProjectTasks,
  setIsNewTask
} = specialAgentsSlice.actions;

export default specialAgentsSlice.reducer;
