import clsx from 'clsx';
import React, { useState } from 'react';

import MarkdownRenderer from 'components/MarkdownRenderer';
import TaskEditModal from './TaskEditModal';

const TaskContent = ({
  title,
  message,
  showFeedback,
  sendFeedback,
  showEditButton = true
}: any) => {
  const [isActive, setIsActive] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleClick = () => {
    setIsActive(!isActive);
  };

  const submitFeedback = (feedback: string) => {
    sendFeedback({ response: feedback });
  };

  return (
    <div className="border border-[#222831] bg-[#121821] rounded-[10px] mb-8">
      <div className="bg-[#121821] py-[10px] px-[18px] flex items-center justify-between">
        <div className="flex justify-start gap-[5px]">
          <img width={20} className="w-[20px] h-[20px]" src="/icons/magic-wand-v2.png" />
          <h4>{title}</h4>
        </div>
        <div className="flex items-center gap-2">
          <span
            className={`cursor-pointer arrow-icon ${isActive ? 'active' : ''}`}
            onClick={handleClick}
          >
            <img src="/message/down-arrow.png" className="mt-1" width="16" alt="" />
          </span>
        </div>
      </div>
      <div className={clsx({ 'p-5': isActive })}>
        {isActive && <MarkdownRenderer content={message} />}
      </div>
      {showFeedback && (
        <div className="m-2 flex gap-[5px]">
          <button onClick={() => submitFeedback('yes')} className="feedback-btn">
            Approve
          </button>
          <button onClick={() => submitFeedback('no')} className="feedback-btn">
            Re Create
          </button>
          {showEditButton && (
            <button onClick={() => setIsModalOpen(true)} className="feedback-btn">
              Edit
            </button>
          )}
        </div>
      )}
      {isModalOpen && showEditButton && (
        <TaskEditModal
          isOpen={isModalOpen}
          onClose={setIsModalOpen}
          content={message}
          sendFeedback={sendFeedback}
        />
      )}
    </div>
  );
};

export default TaskContent;
