import { BASE_API_URL, WS_URL } from './constants';
import { queryBuilder } from './helpers';

export const API_VERSION = 'api/v1';

export const V1_API = `${BASE_API_URL}/${API_VERSION}`;

export const REFRESH_TOKEN_URL = `${V1_API}/auth/refresh-token`;

export const MAIN_WS_URL = `${WS_URL}/ws`;
export const SPECIAL_AGENT_WS_URL = `${WS_URL}/special-agent`;

export const buildUrl = (urlTemplate: string, params: any = null, queryParams: any = null) => {
  let url = urlTemplate.replace(/:([a-zA-Z]+)/g, (_, key) => {
    return params[key] || `:${key}`;
  });

  if (queryParams) {
    const queryParamsStr = queryBuilder(queryParams);
    url = `${url}?${queryParamsStr}`;
  }

  return url;
};

export const API_URLS = {
  // Common URLs
  uploadImage: `${V1_API}/upload-image`,
  // Auth URLs
  refreshToken: `${V1_API}/auth/refresh-token`,
  registration: `${V1_API}/auth/registration`,
  login: `${V1_API}/auth/login`,
  googleLogin: `${V1_API}/oauth/google-login`,
  forgotPasswordRequest: `${V1_API}/auth/forgot-password-request`,
  forgotPasswordReset: `${V1_API}/auth/forgot-password-reset`,
  changePassword: `${V1_API}/auth/change-password`,

  // User URLs
  profile: `${V1_API}/user/profile`,
  userInfo: `${V1_API}/user/info`,
  secretKeys: `${V1_API}/user/secret_keys`,
  secretKeyDetails: `${V1_API}/user/secret_keys/:secretId`,

  // Project URLs
  projectCommonInfo: `${V1_API}/project/common/config`,
  tags: `${V1_API}/tags`,
  projects: `${V1_API}/projects`,
  projectDetails: `${V1_API}/projects/:projectUid`,
  projectUsers: `${V1_API}/projects/:projectUid/users`,
  projectUsersPermission: `${V1_API}/projects/:projectUid/users/:permissionId`,

  tools: `${V1_API}/tools`,
  projectTools: `${V1_API}/projects/:projectUid/tools`,

  getUrls: `${V1_API}/projects/:projectUid/get-urls`,
  ingests: `${V1_API}/projects/:projectUid/embeddings`,
  ingestDetails: `${V1_API}/projects/:projectUid/embeddings/:ingestId`,
  ingestFile: `${V1_API}/projects/:projectUid/embeddings/ingest/file`,
  ingestUpload: `${V1_API}/projects/:projectUid/embeddings/ingest/upload`,

  // Subscription URLs
  subscriptionPlans: `${V1_API}/subscriptions/plans`,
  subscribe: `${V1_API}/subscriptions/subscribe`,
  userSubscriptionDetails: `${V1_API}/subscriptions/details`,
  createSubscriptionSession: `${V1_API}/payments/create-subscription-session`,
  verifySubscriptionSession: `${V1_API}/payments/verify-subscription-session`,
  cancelSubscription: `${V1_API}/payments/cancel-subscription`,

  // Chat URLs
  messages: `${V1_API}/projects/:projectUid/messages`,
  messageDetails: `${V1_API}/projects/:projectUid/messages/:messageId`,

  // Chat Widget URLs
  allChatWidgets: `${V1_API}/chat_widgets`,
  chatWidgets: `${V1_API}/projects/:projectUid/chat_widgets`,
  chatWidgetDetails: `${V1_API}/projects/:projectUid/chat_widgets/:chatWidgetId`,
  toggleGlobalManualMessage: `${V1_API}/projects/:projectUid/chat_widgets/:chatWidgetId/toggle_manual_message`,

  // Customer Chat URLs
  customers: `${V1_API}/projects/:projectUid/chat_widgets/:chatWidgetId/customers`,
  customerDetails: `${V1_API}/projects/:projectUid/chat_widgets/:chatWidgetId/customers/:customerId`,
  customerMessages: `${V1_API}/projects/:projectUid/chat_widgets/:chatWidgetId/customers/:customerId/messages`,
  generateCustomerMessages: `${V1_API}/projects/:projectUid/chat_widgets/:chatWidgetId/customers/:customerId/generate_messages`,

  // Special Agent URLs
  specialAgentsConfig: `${V1_API}/special-agents-config`,
  specialAgents: `${V1_API}/special-agents`,
  specialAgentDetails: `${V1_API}/special-agents/:projectUid`,
  subAgents: `${V1_API}/special-agents/:projectUid/sub-agents`,
  subAgentDetails: `${V1_API}/special-agents/:projectUid/sub-agents/:subAgentId`,
  specialAgentTasks: `${V1_API}/special-agents/:projectUid/tasks`,
  specialAgentTaskDetails: `${V1_API}/special-agents/:projectUid/tasks/:taskId`
};
