export const RECONNECT_DELAY_IN_MS = 1000 * 10; // 10 second delay

export const TOKEN_PADDING_MINUTES = 5;

export const SpecialAgentWSType = {
  ERROR: 'ERROR',
  TASK_CREATED: 'TASK_CREATED',
  INPUT_REQUIRED: 'INPUT_REQUIRED',
  PROGRESS: 'PROGRESS',
  FINAL_RESULT: 'FINAL_RESULT'
};
