import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { SpecialAgents } from 'apis/SpecialAgents';
import { RootStateType } from 'store/store';
import { TASK_LIMIT } from 'core/constants';
import { setProjectTasks, setSpecialAgentDetails } from 'store/reducers/specialAgentsSlice';

import { Layout } from 'components/Layout/Layout';
import TaskContainer from 'components/SpecialAgent/Task/TaskContainer';
import { ITaskDetails } from 'types/specialAgent';

const extractSpecialAgentDetails = (state: RootStateType, projectUid: string | undefined) => {
  if (!projectUid) return null;

  return state.specialAgents.specialAgentDetailsByProjectUid[projectUid];
};

function SpecialAgent() {
  const { projectUid, taskId } = useParams();

  const [taskDetails, setTaskDetails] = useState<ITaskDetails | null>(null);

  const dispatch = useDispatch();

  const isNewTask = useSelector((state: RootStateType) => state.specialAgents.isNewTask);
  const specialAgentDetails = useSelector((state: RootStateType) =>
    extractSpecialAgentDetails(state, projectUid)
  );
  const tasks = useSelector(
    (state: RootStateType) => projectUid && state.specialAgents.projectTasks[projectUid]?.results
  );

  async function loadTasks(projectUid: string, limit: number) {
    const data = await SpecialAgents.getTasks(projectUid, { limit });
    if (data) {
      data.projectUid = projectUid;
      dispatch(setProjectTasks(data));
    }
  }

  async function loadMessageDetails(projectUid: string, taskId: number) {
    const data = await SpecialAgents.getTaskDetails(projectUid, taskId);
    if (data) {
      setTaskDetails(data);
    }
  }

  const loadSpecialAgentDetails = async (pUid: string) => {
    const data = await SpecialAgents.getSpecialAgentDetails(pUid);
    if (data) {
      dispatch(setSpecialAgentDetails({ projectUid, specialAgentDetails: data }));
    }
  };

  useEffect(() => {
    if (projectUid && !specialAgentDetails) {
      loadSpecialAgentDetails(projectUid);
    }
    if (projectUid) {
      loadTasks(projectUid, TASK_LIMIT);
    }
  }, [projectUid]);

  useEffect(() => {
    const existingTask = !isNewTask;
    if (projectUid && taskId && existingTask) {
      loadMessageDetails(projectUid, parseInt(taskId));
    }
  }, [projectUid, taskId, isNewTask]);

  return (
    <Layout tasks={tasks} showTasks showTopBar>
      {projectUid && (
        <TaskContainer
          projectUid={projectUid}
          specialAgentDetails={specialAgentDetails}
          taskId={taskId}
          taskDetails={taskDetails}
          setTaskDetails={setTaskDetails}
        />
      )}
    </Layout>
  );
}

export default SpecialAgent;
