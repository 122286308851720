import React, { useEffect, useState } from 'react';

import { cn } from 'core/helpers/style';

interface AgentThinkingLoaderProps {
  text: string;
  className?: string;
  innerClassName?: string;
  textClassName?: string;
  speed?: number;
  maxDots?: number;
}

export default function Loader({
  text,
  className = '',
  innerClassName = '',
  textClassName = '',
  speed = 500,
  maxDots = 3
}: AgentThinkingLoaderProps) {
  const [dots, setDots] = useState('');

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prevDots) => {
        if (prevDots.length >= maxDots) return '';
        return prevDots + '.';
      });
    }, speed);

    return () => clearInterval(interval);
  }, [maxDots, speed]);

  return (
    <div className={cn('atl-container flex', className)}>
      <div className={innerClassName}>
        <p
          className={cn(
            'atl-text text-lg font-medium text-slate-800 dark:text-slate-200',
            textClassName
          )}
        >
          {text}
          <span className="atl-dots inline-block w-8">{dots}</span>
        </p>
      </div>
    </div>
  );
}
