import React, { useState, useEffect, useRef } from 'react';

import './style.scss';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  className?: string;
  title: string;
  padding?: string;
}

export default function CustomModal({
  isOpen,
  onClose,
  children,
  className = 'modal-default',
  title,
  padding = 'p-8'
}: ModalProps) {
  const [isModalOpen, setIsModalOpen] = useState(isOpen);
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setIsModalOpen(isOpen);
  }, [isOpen]);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        onClose();
      }
    };

    if (isModalOpen) {
      document.addEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isModalOpen, onClose]);

  if (!isModalOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-60">
      <div className={`relative ${className}`}>
        <div
          ref={modalRef}
          className="bg-[#191F28] border-[#64BFF3] border-t-2 rounded-lg shadow-xl overflow-hidden"
        >
          <div className="overflow-y-auto max-h-[calc(90vh-2rem)]">
            <div className={'' + padding}>
              <div className={'flex items-center justify-between mb-10'}>
                <h3 className="text-2xl	font-semibold">{title}</h3>
                <button onClick={() => onClose()}>
                  <img className="w-6 h-6" src="/icons/cross-icon.svg" alt="Cross" />
                </button>
              </div>
              <div>{children}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
